<template lang="pug">
  v-container
    v-toolbar(
      color='primary'
      dark
    )
      span Supplier Categories
      v-spacer
      v-icon mdi-account-network
    v-data-table(
      :loading='splrCatGetting'
      :headers='headers'
      :items='splrCatList'
    )
</template>
<script>
import splrCatRepository from '@/repositories/supplier-category.repository'
import { getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const splrCatGetDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Descrition', value: 'description' },
  ],
})

export default {
  name: 'SupplierCategoryTable',
  created () {
    this.getSplrCat()
    this.websocketEvents()
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('splr-cat'),
    }
  },
  methods: {
    getSplrCat () {
      if (this.splrCatGetting) return
      this.splrCatGetting = true
      this.splrCatGetErrors = []
      splrCatGetDelay(() => {
        splrCatRepository.index()
          .then(({ data }) => { this.splrCatList = data })
          .catch(e => { this.splrCatGetErrors = errorHandler(e) })
          .then(() => { this.splrCatGetting = false })
      })
    },
    websocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.channel('database.updated')
        .listen('SupplierCategoryUpdatedEvent', e => { this.getSplrCat() })
    },
  },
}
</script>
